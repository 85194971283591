@import '../../../styles/scss/variables.scss';

.container {
    width: 100%;
    //background-color: rgba(43, 43, 43, 0.7);
    background: linear-gradient(to bottom, rgba(43, 43, 43, 0.7), rgba(43, 43, 43, 0.4));
    border: 1px solid rgba(124, 124, 124, 0.2);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 10px;
}

.p2 {
    font-size: 1em;
    font-weight: 600;
    font-style: italic;
    margin-top: 5%;
}

.imageContainer {
    margin-left: 8%;
}
.imageContainer2 {
    width: 80%;
}

.starFilled {
    color: gold;
    margin-right: 5px;
    font-size: 2em;
}

.imageContainer2 img {
    border-radius: 20px;
}

@media (min-width: 1024px) {
    .container {
        min-height: 750px;
        max-height: 750px;
    }
}