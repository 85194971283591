@import "./variables.scss";
@import '../../../node_modules/bootstrap/scss/bootstrap';

body {
    background-color: $info;
    color:$primary;
    overflow-x: hidden;
    max-width: 100vw !important;
}

h1 {
    color:rgba(232, 232, 232, 0.9);
    font-weight: 800;
    font-size: 2em;
}

h2, h3, h4, h5, h6 {
    color:rgba(219, 219, 219, 0.9);
}

.p-margin-top {
    margin-top: 5%;
}

.bottom-filler {
    margin-bottom: 50vh;
}

.regular-filler {
    margin-top: 15%;
}


@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(200px);
    }
    100% {
        opacity:1;
        transform: translateY(0);
    }
}

.home-container {
    opacity:0;
    animation: fadeInUp 1s ease forwards;
}

.appearing-container {
    margin-top: 15%;
    margin-bottom: 15%;
    opacity:0;
    animation: fadeInUp 2s ease forwards;
}

.corner {
    border-radius: 20px;
}

.running {
    border-radius: 50%;
}

.rounded-card {
    border-radius: 20px;
}

@media (min-width: 1024px) {

    .appearing-container {
        margin-top: 0;
        margin-bottom: 0;
    }

    .p-margin-top {
        font-size: 1.3em;
    }

    .outlet {
        max-width: 1350px;
    }
}