@import '../../../styles/scss/variables.scss';

.header {
    display: flex;
    width: 100%;
    margin: auto;
    justify-content: center;
    align-items: center;
}

.overskrift {
    margin-bottom: 0;
    margin-top: 5px;
    padding:0;
    color:$secondary;
    font-size: 1.2em;
}

.overskrift2 {
    margin-bottom: 15px;
    margin-top: 15px;
    padding:0;
    color:$secondary;
    font-size: 2em;
    text-align: center;
    color: #F2f2f2;
}

.iconContainer {
    width: 7%;
    display: flex;
    justify-content: center; /* Sentrerer ikonet horisontalt i containeren */
    align-items: center;     /* Sentrerer ikonet vertikalt */
    margin-right: 5%;
}

.iconContainer img {
    vertical-align: middle;  /* Sikrer at bildet justeres med teksten */
}

@media (min-width: 1024px) {
    .iconContainer {
        width:3%;
    }

    .container {
        width: 70%;
        margin: auto;
    }
}