.hidden {
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  }
  
  .visible {
    opacity: 1;
    transform: translateY(0);
    margin-bottom: 15%;
  }

@media (min-width: 1024px) {
    .visible{
        margin-bottom: 5%;
    }
}