@import '../../../../styles/scss/variables.scss';

.cardContainer {
    display: flex;
    flex-direction: column;
    width:100%;
    //background-color: rgba(43, 43, 43, 0.7);
    background: linear-gradient(to bottom, rgba(43, 43, 43, 0.7), rgba(43, 43, 43, 0.4)); 
    border: 1px solid rgba(124, 124, 124, 0.2);
    // padding-left: 5%;
    padding-top: 5%;
}

.cardContainer h3, .cardContainer p {
    padding-left: 5%;
}

.childCard {
    display: flex;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
    background-color: $info;
    border: 1px solid rgba(124, 124, 124, 0.2);
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 3%;
    padding-bottom: 3%;
    justify-content: space-between;
}

.iconContainer {
    width: 100%;
    margin: auto;
    height:auto;
    display: flex;
    justify-content: space-evenly;
}

.iconChild {
    width: 15%;
}

.childCardChild {
    display: flex;
    flex-direction: column;
    width:50%;
    justify-content: center;
    padding-top: 3%;
}

.childCardChild p, .childCardChild h6 {
    margin:0;
    padding:0;
}

.childCardChild p {
    font-size: 0.7em;
    color: rgba(219, 219, 219, 0.4) 
}

.childCardChildSub {
    display: flex;
    width: 100%;
    align-items: center;
    height:1em;
}

.dot {
    display: inline-block;
    width:10px;
    height:10px;
    background-color: $secondary;
    border-radius: 50%;
    margin-right: 5%;
}

.dotText {
    color:$secondary;
    font-size: 0.8em;
}

@media (min-width: 1024px) {
    .cardContainer {
        //width: 500px;
        min-height: 400px;
        justify-content: space-evenly;
    }

    .childCard{
        width: 85%;
    }
}