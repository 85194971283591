.button {
    border-radius: 30px;
    width: 30%;
    color: #000;
    font-size: 1.1em;
}

.body {
    font-size: 1.3em;
}

.title {
    font-size: 1.5em;
    font-style: italic;
    font-weight: 600;
}