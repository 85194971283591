@import '../../styles/scss/variables.scss';

.accordionItem {
    border-bottom: 1px solid $primary;
    margin-bottom: 10px;
    width:100%;
  }
  
  .accordionHeader {
    display: flex;
    flex-direction: row;
    width:100%;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    cursor: pointer;
  }
  
  .accordionTitle {
    flex-grow: 1;
    margin-left: 10px;
    font-weight: bold;
  }

  .accordionTitle h4{
    margin:0;
    
  }
  
  .iconContainer {
    width:10%;
  }
//   .accordion-icon-left img {
//     width: 24px;
//     height: 24px;
//   }
  
  .accordionIcon {
    transition: transform 0.5s ease;
  }
  
  .accordionIcon.rotate {
    transform: rotate(360deg);
  }
  
  .accordionContent {
    //display:none;
    max-height: 0;
    overflow: hidden;
    padding-left: 10px;
    transition: max-height 0.3s ease, padding 0.3s ease, opacity 0.4s ease-in-out;
    opacity: 0;
  }
  
  .accordionContent.open {
    //display:block;
    opacity:1;
    max-height: 500px;
    padding: 10px;
  }
  
  @media (min-width: 1024px) {
    .iconContainer{
        width: 3%;
    }

    .accordionItem {
      width:70%;
      margin: auto;
    }
  }