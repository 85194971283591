@import "../../styles/scss/variables.scss";

.accordionItem {
    margin-bottom: 10px;
    border: 1px solid transparent;
    transition: border 0.3s ease; 
    padding-top: 5px;
    padding-bottom: 10px;
  }

  .accordionItem.open {
    border-color: $secondary;
    //background-color: rgba(43, 43, 43, 0.7);
    background: linear-gradient(to bottom, rgba(43, 43, 43, 0.7), rgba(43, 43, 43, 0.4));
}
  
  .accordionHeader {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    cursor: pointer;
  }
  
  .accordionTitle {
    font-size: 1.2rem;
    width: 80%;
  }
  
  .accordionIconRight {
    width: 20px;
    height: 20px;
    position: relative;
    cursor: pointer;
  }
  
  .accordionIconRight span {
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: $secondary;
    transition: all 0.3s ease;
    border-radius: 3px;
  }
  
  .accordionIconRight span:nth-child(1) {
    top: 8px;
  }
  
  .accordionIconRight span:nth-child(2) {
    top: 8px;
    transform: rotate(90deg);
  }
  
  /* Når accordion er åpen */
  .accordionIconRight.open span:nth-child(1) {
    transform: rotate(45deg);
    top: 8px;
  }
  
  .accordionIconRight.open span:nth-child(2) {
    transform: rotate(-45deg);
    top: 8px;
  }
  
  .accordionContent {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
    padding-left: 10px;
  }
  
  .accordionContent.open {
    max-height: 200px; /* Juster denne verdien for innholdets høyde */
  }  