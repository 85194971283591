@import '../../styles/scss/variables.scss';

.container {
    width:100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // border-bottom: 1px solid rgba(194, 194, 194, 0.9);
    padding-bottom: 1.5%;
    padding-top: 3%;
    padding-left: 2%;
    transition: height 0.3s ease;
    flex-wrap: wrap;
    height:auto;
    position: relative;
    overflow: hidden;
  }
  
  .container.open {
    // border-bottom-color: rgba(194,194,194, 0.9);
    padding-bottom: 100vh;
  }
  
  .container::after {
    content:'';
    position:absolute;
    bottom:0;
    left:0;
    width:100%;
    height: 1px;
    //background-color: rgba(194,194,194, 0.9);
    transform: translateY(0);
    transition: transform 0.3s ease;
  }
  
  // .container.open::after {
  //   transform: translateY(0);
  // }
  
  .imageContainer{
    width: 55%;
    color: $secondary;
  }

  .imageContainer:hover {
    cursor: pointer;
  }
  
  .buttonContainer{
    cursor: pointer;
    margin-right: 2%;
    border-radius: 50%;
    border: 2px solid rgba(194, 194, 194, 0.2);
    width: 60px;
    height:60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .menuIcon {
    position:relative;
    width: 20px;
    height: 20px;
    top: 2px;
  }
  
  .menuIcon span {
    position:absolute;
    width:100%;
    height:3px;
    // background-color: rgba(219, 219, 219, 0.9);
    background-color: $secondary;
    left:0;
    transition: all 0.3s ease;
    border-radius: 10px;
  }
  
  .menuIcon span:nth-child(1) {
    top: 0;
  }
  
  .menuIcon span:nth-child(2) {
    top: 6px;
  }
  
  .menuIcon span:nth-child(3) {
    top: 12px;
  }
  
  /* When the menu is open, turn the button into an "X" */
  .menuIcon.open span:nth-child(1) {
    transform: rotate(45deg);
    top: 8px;
  }
  
  .menuIcon.open span:nth-child(2) {
    opacity: 0;
  }
  
  .menuIcon.open span:nth-child(3) {
    transform: rotate(-45deg);
    top: 8px;
  }
  
  /* Link container (hidden by default) */
  .linksContainer {
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width:100%;
  }
  
  /* Når menyen er åpen */
  .linksContainer.show {
    height: 500px; /* Sett høyden basert på antall linker */
    margin-top: 10vh;
  }
  
  .link {
    padding: 10px 0;
    text-align: center;
    font-size: 1.2rem;
    border-bottom: 1px solid rgba(194, 194, 194, 0.9);
    width: 90%;
    margin: auto;
    text-align: left;
  }
  
  .link:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  
  @media (min-width: 1024px) {
    .container{
      max-height: 150px;
    }
  
    .imageContainer{
      width: 10%;
    }
  }