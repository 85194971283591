@import '../../styles/scss/variables.scss';

.form {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 3vh;
    padding-bottom: 3vh;
}

.form input {
    border-radius: 10px 0 10px 0;
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 1%;
    padding-bottom: 1%;
    width: 80%;
    border: 1px solid $secondary;
    // background-color: rgba(43, 43, 43, 0.7);
    background-color: $info;
    font-style: italic;
    font-size: 1.5em;
    color: $primary;
    margin-bottom: 5%;
}

.form textarea {
    border-radius: 10px 0 10px 0;
    padding-left: 1vw;
    padding-right: 1vw;
    width: 100%;
    height: 20vh;
    //background-color: rgba(43, 43, 43, 0.7);
    background-color: $info;
    border: 1px solid $secondary;
    font-style: italic;
    font-size: 1.5em;
    color: $primary;
}

.form button {
    border-radius: 20px;
    width: 80%;
    margin-top: 5vh;
    color: $secondary;
    //background-color: rgba(43, 43, 43, 0.7);
    background-color: $info;
    border: 1px solid $secondary;
    transition: 0.2s ease background-color;
    font-size: 1.5em;
}

.form button:hover {
    background-color: $secondary;
    color:#000;
}

.container {
    opacity: 1;
    //background-color: rgba(43, 43, 43, 0.7);
    background: linear-gradient(to bottom, rgba(43, 43, 43, 0.7), rgba(43, 43, 43, 0.4));
    border: 1px solid rgba(124, 124, 124, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container2 {
    background-color: #01CAFD;
    border-radius: 30px;
    width: 100%;
    margin:auto;
    z-index: 2;
    text-align: center;
}

.container3 {
    width: 90%;
    padding-top: 5vh;
    margin: auto;
}

.container h2 {
    padding-top: 2vh;
    font-size: 2em;
}

.container3 p {
    font-size: 1.5em;
}


.hidden {
    opacity: 0;
    position:relative;
    transform: translateY(75%);
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-out;
}

.visible {
    opacity: 1;
    transform: translateY(0);
}

@media (min-width: 720px) {
    /* .container {
        width: 100%;
    } */
    .container2 {
        width: 45%;
    }
}