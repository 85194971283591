@import '../../styles/scss/variables.scss';

.subHeader {
    display: flex;
    width: 100%;
    min-width: 350px;
    margin: auto;
    justify-content: center;
    align-items: center;
}

.overskrift {
    margin-bottom: 0;
    margin-top: 5px;
    padding:0;
    color:$secondary;
    font-size: 1.2em;
}

.starContainer {
    width: 20px;
    display: flex;
    justify-content: center; /* Sentrerer ikonet horisontalt i containeren */
    align-items: center;     /* Sentrerer ikonet vertikalt */
    margin-right: 5%;
}

.starContainer img {
    vertical-align: middle;  /* Sikrer at bildet justeres med teksten */
}

@media (min-width: 1024px) {
    .starContainer img {
        max-height: 40px;
    }
}