.container {
    overflow-x: hidden; /* Hindrer horisontal scrolling */
    white-space: nowrap; /* Hindrer kortene fra å bryte til en ny linje */
    width: 100vw; /* Bruk viewport bredden */
    max-width: 100vw; /* Sørger for at containeren aldri overstiger bredden på vinduet */
    padding: 20px 0; /* Ekstra padding for utseende */
    scrollbar-width: none; /* Skjuler scrollbaren i Firefox */
    -ms-overflow-style: none; /* Skjuler scrollbaren i IE/Edge */
}

/* Skjuler scrollbaren for Chrome, Safari og nyere Edge */
.container::-webkit-scrollbar {
    display: none;
}

.cardContainer {
    display: flex; /* Bruker flexbox for å kontrollere layout */
    flex-wrap: nowrap; /* Hindrer wrapping til ny linje */
    width: max-content; /* Lar innholdet utvide seg naturlig */
    gap: 20px; /* Gir mellomrom mellom kortene */
}

.card {
    width: 250px; /* Fast bredde på kortene for å unngå at de blir for store */
    flex-shrink: 0; /* Hindrer at kortene krymper */
    padding: 10px;
    // background-color: #f9f9f9;
    // border-radius: 8px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    //box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Litt skygge for visuelt bedre kort */
}

// .cardFlyet {
//     width: 250px;
//     max-height: 250px; /* Fast bredde på kortene for å unngå at de blir for store */
//     flex-shrink: 0; /* Hindrer at kortene krymper */
//     padding: 10px;
//     background-color: #f9f9f9;
//     border-radius: 20px;
//     text-align: center;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Litt skygge for visuelt bedre kort */
// }

