@charset "UTF-8";
.accordionItem {
  margin-bottom: 10px;
  border: 1px solid transparent;
  -webkit-transition: border 0.3s ease;
  transition: border 0.3s ease;
  padding-top: 5px;
  padding-bottom: 10px;
}

.accordionItem.open {
  border-color: #F5FF00;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(43, 43, 43, 0.7)), to(rgba(43, 43, 43, 0.4)));
  background: linear-gradient(to bottom, rgba(43, 43, 43, 0.7), rgba(43, 43, 43, 0.4));
}

.accordionHeader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 10px;
  cursor: pointer;
}

.accordionTitle {
  font-size: 1.2rem;
  width: 80%;
}

.accordionIconRight {
  width: 20px;
  height: 20px;
  position: relative;
  cursor: pointer;
}

.accordionIconRight span {
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: #F5FF00;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 3px;
}

.accordionIconRight span:nth-child(1) {
  top: 8px;
}

.accordionIconRight span:nth-child(2) {
  top: 8px;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

/* Når accordion er åpen */
.accordionIconRight.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  top: 8px;
}

.accordionIconRight.open span:nth-child(2) {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  top: 8px;
}

.accordionContent {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.3s ease;
  transition: max-height 0.3s ease;
  padding-left: 10px;
}

.accordionContent.open {
  max-height: 200px;
  /* Juster denne verdien for innholdets høyde */
}
