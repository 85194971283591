@import '../../styles/scss/variables.scss';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10%;
}

.childParent {
    display: flex;
    width: 90%;
    margin: auto;
    justify-content: space-between;
}

.child:hover {
    text-decoration: underline;
    cursor: pointer;
    color:$secondary;
}

.madeBy {
    margin-top: 3%;
    font-style: italic;
}