@import '../../../styles/scss/variables.scss';

.imageContainer {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
}

.homeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width:90%;
    margin: auto;
}

// .infiniteContainer {
//     width: 90%;
//     margin-left: auto;
//     margin-right: auto;
// }

.container {
    overflow: hidden;
}

.subHeader {
    display: flex;
    width: 100%;
    margin: auto;
    justify-content: center;
    align-items: center;
}

.overskrift {
    margin-bottom: 0;
    margin-top: 5px;
    padding:0;
    color:$secondary;
    font-size: 1.2em;
}

.starContainer {
    width: 7%;
    display: flex;
    justify-content: center; /* Sentrerer ikonet horisontalt i containeren */
    align-items: center;     /* Sentrerer ikonet vertikalt */
    margin-right: 5%;
}

.starContainer img {
    vertical-align: middle;  /* Sikrer at bildet justeres med teksten */
}

.homeSub {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (min-width: 1024px) {
    // .double1{
    //     display:flex;
    //     width: 100%;
    //     justify-content: center;
    //     align-items: center;
    // }

    .starContainer {
        width: 3%;
    }

    .homeSub {
        flex-direction: row;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 5%;
        justify-content: space-between;
    }

    .imageContainer {
        width: 40%;
    }

    .container {
        width: 55%;
    }
}